@import '../../colours.scss';

@font-face {
    font-family: "montserrat";
    src: url("../common/Montserrat-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.app-header {
    position: absolute;
    top: 0px;
    height: fit-content;
    width: 100%;
    z-index: 950;
    border: none;
    outline: none;
}

.ui.app-header.menu {
    background: lightgrey;
}

.ui.app-header.menu .header.item img {
    width: 6rem;
}

.ui.app-header.menu.menu .item {
    padding: 5px 15px !important;
    color: black;
}

.ui.app-header.menu .header.item {
    background-image: linear-gradient(to right, $light-gold, $dark-gold);
    padding-right: 100px !important;
    display: grid;
    grid-template-columns: 20% 56% 6% 6% 6% 6%;
}

.ui.app-header.menu .header.item:before {
    width: 0px !important;
}

.ui.app-header.menu .header.item img {
    margin-right: 10px;
}

.main-app {
    z-index: 950;
}

.login.panel-wrapper {
    top: auto !important;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: none;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}

.login-content {
    font-family: "montserrat";
    text-align: left;
    color: $dark-gold;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5%;
}

.login-overlay {
    background: #000000c2;
    z-index: 999;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1.0;
}

.login-panel {
    font-family: "montserrat";
    border-radius: 1%;
    background-color: white;
    width: 30%;
    font-size: larger;
}

.login-title-panel {
    background: $dark-gold;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: grid;
    grid-template-columns: 80% 20%;
    font-family: "montserrat";
    font-size: large;
    align-items: center;
    margin-bottom: 5%;
    text-align: left;
    padding: 20px 5% 20px 5%;
}

.login-title-panel img {
    width: 90%;
    max-width: 200px;
}

.ua-login-form input {
    border: 2px solid $light-gold  !important;
    font-family: "montserrat" !important;
}

.ua-login-form .error.field input {
    border: 2px solid #e48611 !important;
    font-family: "montserrat" !important;
}

.ua-login-form label {
    color: $dark-gold  !important;
}

.ui.form.ua-login-form .error.field .ui.prompt.label {
    border: none !important;
    margin-top: 0 !important;
}

.ui.form.ua-login-form .error.field .ui.prompt.label:before {
    content: none
}

.register,
.ui.button.register {
    float: right;
    color: $primary-dark;
    height: 45px;
    width: 25%;
    border: 0px;
    border-radius: 7px;
    outline: none;
    font-family: "montserrat";
}

.login-button,
.ui.button.login-button {
    color: white;
    background-color: $dark-gold;
    height: 45px;
    width: 25%;
    border: 0px;
    border-radius: 7px;
    outline: none;
    font-family: "montserrat";
}

.login-button:active,
.ui.button.login-button:active,
.login-button:hover,
.ui.button.login-button:hover {
    background-color: $primary-dark  !important;
    color: $primary-light  !important;
}

.login-button:focus,
.ui.button.login-button:focus {
    background-color: $light-dark-gold;
    color: $primary-light;
}

.login-button:disabled,
.ui.button.login-button:disabled {
    background-color: #c1c0be;
}

.login-button:disabled:hover,
.ui.button.login-button:disabled:hover {
    content: none;
}

.scenario-button,
.ui.button.scenario-button {
    color: white;
    background-color: $dark-gold;
    height: 45px;
    min-width: 300px;
    border: 0px;
    border-radius: 7px;
    outline: none;
    font-family: "montserrat";
    font-size: medium;
}

.scenario-button:hover,
.ui.button.scenario-button:hover {
    background-color: $primary-dark;
    color: $primary-light;
}

.ui.button.scenario-button.retry {
    min-width: 100px;
    font-size: small;
    height: 35px;
    margin: 10px !important;
}

.startup-window {
    z-index: 401;
    position: fixed;
    left: 15px;
    top: 75px;
    height: var(--scenario-window-height);
    width: 335px;
}

.scenario-select {
    overflow-y: auto;
    max-height: 500px;
    line-height: 1.14285714em;
    margin: 10px;
}

.scenario-select::-webkit-scrollbar {
    width: 5px;
}

.scenario-select::-webkit-scrollbar-thumb {
    background-color: $primary-dark;
}

.scenarios-dropdown {
    display: flex;
    cursor: pointer;
}

.scenarios-dropdown p {
    font-size: large;
    flex: 5;
}

.live-scenarios {
    padding: 10px;
}

.live-scenarios button {
    margin: 5px !important;
    max-width: 10px !important;
}

.login-content {
    text-align: left;
}

.forgotten-password {
    cursor: pointer;
    text-decoration: underline;
    margin-top: 5%;
}

.actions-box {
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    background: white;
    border: solid $primary-dark;
}

.login-actions-content {
    width: 100%;
    height: 100%;
    padding: 5px;
    color: black;
}

.login.panel-wrapper.user-actions {
    transform: rotateY(180deg);
}

.users-table table .ui.button {
    margin: 2px 0 2px 0 !important;
    width: 45px;
    padding: 12px;
}

.users-table table button i.icon {
    margin: 0px !important;
}

.login-actions-content button {
    margin: 15px 0 !important;
}

.login-actions-content .ui.list {
    margin: 0;
}

.scenario-select .ui.massive.active.text.centered.inline.loader {
    margin-top: 50px;
}

.ui.massive.active.text.centered.inline.loader {
    color: grey;
}

.ui.dimmer .ui.loader:after {
    border-color: grey transparent transparent !important;
}

.scenario-select .ui.progress {
    margin: 10px !important;
    margin-top: 80px !important;
}

.item.error-content {
    line-height: 6.2rem !important;
}

.item.scenario-selection {
    border: solid;
    border-radius: 5px;
    border-color: grey;
    padding: 10px !important;
    margin: 10px;
    font-size: x-large;
    background: darkgrey;
    cursor: pointer;
}

.left-align {
    text-align: left;
}

.two-btns button:last-child {
    position: absolute;
    right: 0;
}

.filter {
    padding-bottom: 5px;
    padding-right: 5px;
    position: absolute;
    bottom: 0;
    align-items: center;
    width: 100%;
    font-size: large;
    color: grey;
}

.filter input {
    background: transparent;
    border-radius: 25px;
    border: 2px solid grey;
}

.filter input:focus {
    outline: none;
}

.filter span {
    padding-right: 5px;
}

.startup-actions-li {
    margin-bottom: 15px;
    color: $primary-dark;
}

.startup-actions-li button {
    border: none;
    position: relative;
    color: white;
    font-family: "montserrat";
}

.ua-header {
    width: 100%;
    height: 60px;
    align-items: center;
    position: absolute;
    top: 0px;
    z-index: 300;
    background-color: var(--app-header-background);
    display: flex;
}

.ua-header-invisible {
    width: 400px;
    background:  rgba(0,0,0,0);
}

.ua-header>button {
    height: 100%;
    width: 140px;
    background: transparent;
    outline: none;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.27);
}

.ua-header>img {
    position: relative;
    left: 25px;
    padding: 7px;
    height: 100%;
}

.ua-header .ua-header-title {
    padding: 5px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;
}

.ua-header-title .scenario-title {
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 26px;
}

.ua-header-title .simulation-title {
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 16px;
}

.ua-header-title .title-div {
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: block;
    overflow-y: hidden;
}

.ua-header-title .title-div:hover {
    color: $dark-gold;
}

.ua-modal.title-modal {
    width: 500px !important;
}

.ui.message.ua-error {
    padding: 0.5em 1em;
}

.ui.message.ua-error p {
    text-align: left;
}

.user-window .panel-wrapper {
    right: 15px;
    top: 75px;
    max-height: 500px;
}

.user-window .panel-wrapper.mounted {
    width: 500px;
}

.user-window .panel-wrapper-content {
    padding: 0 !important;
}

button.logout {
    min-width: 100px;
}

.navigation-button {
    font-family: "montserrat";
    font-size: 15px;
}

.scenario-loader-div {
    padding: 15px 0 15px 0;
}

.scenario-loader {
    display: inline-flex;
}

.data-loader-icon {
    display: table-cell !important;
    text-align: center !important;
    width: 45px;
    height: 45px;
    color: white;
    stroke: white;
    fill: white;
    border-radius: 9px;
    padding: 10px;
    background: darkgray;
    margin: 2px;
}

.scenario-loader-loading {
    margin-top: 10px;
}

.data-loader-icon.success {
    background: $dark-gold;
}

.data-loader-icon.error {
    background: $error-red;
}

.ui.table.ua-table,
.ui.table.ua-table thead th {
    color: black
}

.ui.table.ua-table .checkbox {
    margin: 5px
}

.ui.modal.ua-modal>.header:not(.ui) {
    background: $primary-dark;
    color: white;
    border: none;
    border-radius: 0px;
}

.ui.modal.ua-modal {
    border: solid $primary-dark;
    border-radius: 0px;
}

.ui.modal.ua-modal .content {
    max-height: 250px;
    overflow-y: auto;
}

.ui.modal.ua-modal .ui.button.positive {
    background-color: $dark-gold;
}

.ui.modal.ua-modal .ui.button.primary {
    background-color: $dark-gold;
}

.scenario-row .scenario {
    background-color: $pale-gold;
    padding: 10px;
    cursor: pointer;
}

.scenario-row .scenario:hover {
    color: white;
    background-color: $light-gold;
}

.scenario-row .scenario .scenario-name {
    padding: 2px;
    font-size: 16px;
}

.scenario-row .scenario .scenario-created {
    font-size: 12px;
    padding: 1px;
}

.scenario-row .scenario .scenario-user {
    font-size: 10px;
    padding: 1px;
}

.scenario-archive {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.scenario-archive .data-loader-icon {
    width: 33px;
    height: 33px;
    padding: 5px;
    background: $dark-gold;
}

.scenario-archive .data-loader-icon:hover {
    background: $primary-dark;
}

.ui.button.restore-scenarios {
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 70%;
    cursor: pointer;
    color: $primary-light;
    background-color: $dark-gold;
    border-radius: 5px;

}

.ui.button.restore-scenarios:hover {
    background-color: $primary-dark;
    color: $primary-light;
}

.ua-tab {
    background: white !important;
}

.ua-tab .ui.attached.tabular.menu {
    background: #666666 !important;
}

.ua-tab .ui.segment {
    background: white !important;
    border: none !important;
    padding: 0;
}

.ua-tab .item {
    color: white !important;
}

.ua-tab .active.item {
    background: white !important;
    color: grey !important;
}

.no-padding {
    padding: none !important;
}

.hidden {
    display: none !important;
}

.padding-ten {
    padding: 10px;
}

.users-table {
    font-size: 0.92857143em;
    padding: 0;
}