.right.sidebar-div {
    right: 10px;
    left: auto;
}

// .sidebar-div .ui.vertical.menu {
//     width: 50px;
//     box-shadow: none;
//     background: none;
//     border: none;
// }

// .sidebar-div a.item {
//     width: 50px;
//     height: 50px;
//     text-align: center;
// }

// .sidebar-div a.item i {
//     color: grey;
//     font-size: x-large;
// }

.item.icon-container {
    padding: 0px !important;
    display: inline-block !important;
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    background: none !important;
    text-align: center !important;
}

.item.icon-container:before {
    content: '' !important;
    display: inline-block !important;
    height: 100% !important;
    vertical-align: middle !important;
    background: none !important;
}

.sidebar-div {
    opacity: 0;
    transition:  opacity 0.5s linear;
}

.sidebar-div.mounted {
    opacity: 1.0;
}