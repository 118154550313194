@import '../../../colours.scss';

.legend-div {
    max-height: 70vh;
    width: 100%;
}
.content-body.legends-card {
    overflow: unset;
}

.legend-div .legend {    background: rgba(255, 255, 255, 0.55);
    padding: 20px 70px 20px 70px;
    height: auto;
    width: 100%;
    max-height: 69vh;
    object-fit: contain;
}

.legend-tool-legend {
    list-style: none;
}

.legend-tool-legend li {
    color: black;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
    line-height: 25px;
}

.legend-tool-legend span { 
    border: 1px solid #ccc;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
    margin-right: 10px;
}

.plot-holder {
    padding-top: 0px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition:  max-height 0.25s linear, opacity 0.25s linear, padding-top 0.25s linear;
}

.plot-holder.show {
    padding-top: 5px;
    max-height: 310px;
    opacity: 1.0;
    overflow: hidden;
}

.plot-holder .rv-xy-plot {
    position: absolute;
    padding-left: 5px;
}

.plot-holder {
    .rv-radial-chart__series--pie__slice:hover {
      stroke: lightgrey !important;
      stroke-width: 4px !important;
    }
}

.data-breakdown {
    border: solid 1px;
    border-radius: 5px;
    border-color: $pale-gold;
    padding: 7px;
    margin: 10px 0px 7px 0px;
}

.ui.form .field > label {
    margin: 0em 0em 0.28571429rem 0em !important;
}

.data-breakdown .header {
    cursor: pointer;
    padding: 5px;
    font-size: medium;
}

.data-breakdown .header label {
    cursor: pointer;
}

.data-breakdown .header i {
    float: right;
    transition: transform 0.5s linear;
}

.data-breakdown .header i.show {
    transform: rotateX(180deg);
}

.chart-hint {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: large;
    color: black;
}

.shape-content {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ui.form .shape-content .field {
    margin: 0;
}

.shape-content.path, .shape-content.line {
    overflow: unset;
}

.shape-content::-webkit-scrollbar {
    width: 5px;
}

.shape-content::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

label {
    color: grey;
    font-weight: bold;
}

.ui.table tr td, .ui.table thead th {
    padding: 3px!important;
}

.ui.table tr td.actions {
    width: 5.2rem;
    text-align: right;
}

.ui.table tr td.actions i {
    margin: 0px 0.75rem;
}

.input {
    width: 328px;
    padding-bottom: 8px;
}

.ui.input.input input {
    color: $primary-dark;
}

.ui.radio.checkbox {
    margin: 10px 15px 0px 0px;
}

.ui.radio.checkbox label {
    color: $primary-dark;
}

.content-body.measuring-tool-card {
    overflow: unset;
}

.simulation-filter.content-body {
    overflow: unset;
}