@import "../../../colours";

@font-face {
  font-family: "montserrat";
  src: url("../../common/Montserrat-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --input-min-height: 40px;
  --input-width: 70%;
}

.sources-table {
  padding: 10px;
  max-height: 250px;
  overflow-x: auto;
}

.sources-table .ui.table tr.active {
  background: rgba(255, 165, 0, 0.5) !important;
  color: $primary-light;
}

.sources-table thead {
  border: none;
  border-bottom: grey;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.sources-table th,
.sources-table td {
  border: none !important;
  background: none !important;
  color: $primary-dark  !important;
}

.sources-table table {
  background: none !important;
  border: none !important;
}

.table-buttons {
  flex: 1;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.table-buttons .ui.button {
  background: $primary-dark  !important;
}

.table-buttons .ui.buttons {
  flex: 1;
  margin: 0 14px 0 14px;
  max-width: 100px;
}

.table-buttons .ui.buttons .button:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right: antiquewhite;
  border-right-style: solid;
  border-width: 1px;
}

.table-buttons .ui.buttons .button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-left: antiquewhite;
  border-left-style: solid;
  border-width: 1px;
}

.sources-panel {
  height: 100%;
  width: 100%;
}

.sources-panel .sources-panel-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 280px;
  transition: height 1.5s ease-out;
  top: 0px;
}

.sources-panel .sources-panel-wrapper-header {
  background: $primary-dark;
  padding: 5px;
  // text-align: end;
  display: flow-root;
}

.sources-panel .sources-panel-wrapper-header .title {
  float: left;
  font-size: large;
  padding: 0px 0px 0px 5px;
}

.sources-panel .sources-panel-wrapper-header .icon {
  float: right;
}

.wrapper-progress {
  bottom: 0px;
  width: 100%;
  text-align: center;
}

.right-side {
  text-align: right;
}

.finished Button {
  background-color: transparent !important;
}

.finished .ui.button {
  width: 100%;
  border-radius: 0;
}

.panel-dot {
  font-size: 1em !important;
  transition: font-size 0.5s linear;
  color: grey;
}

.active-dot {
  font-size: 1.5em !important;
  transition: font-size 0.5s linear;
}

.point-properties-card {
  // max-height: 550px;
  height: auto;
}

.sources-panel-wrapper .point-properties-card {
  transition: max-height 1.5s height 1.5s ease-out;
}

.point-location-card {
  margin-top: 0px;
  margin-bottom: -23px;
  font-family: "montserrat";
  display: flex;
  position: relative;
  right: -59px;
}

.point-location-card Button {
  max-height: 49px;
  border-radius: 10px !important;
  background-color: $light-gold  !important;
}

.point-location-position {
  width: 206px;
  margin-top: 0px;
  margin-bottom: -23px;
  font-family: "montserrat";
  display: flex;
  position: relative;
  right: -59px;
}

.met-location-position {
  width: 206px;
}

.moving-location-position {
  width: 145px;
}

.line-location-position {
  display: flex;
  position: relative;
}

.map-select {
  margin-top: 18px !important;
}

.map-select i {
  margin: auto !important;
}

.invisible {
  display: none !important;
}

.shape-create-field {
  width: inherit;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 5px;
  font-size: 1em;
  background: #ffffff;
  border: 1px solid #4a1331;
  color: #1b1c1d;
  border-radius: 0.28571429rem;
  box-shadow: 0em 0em 0em 0em transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  display: inline-block;
}

.inline-btns {
  display: flex;
}

.inline-btns .button {
  width: 100%;
}

.shape-create-field .info {
  float: left;
  width: 210px;
}

.shape-create-edit-buttons {
  display: block;
}

.shape-create-edit-buttons button {
  max-height: 49px;
  background-color: rgb(240, 163, 6) !important;
  border-radius: 10px !important;
}

.dropdown {
  color: $primary-dark;
  transition: transform 0.5s linear;
}

.dropdown.show {
  transform: rotateX(180deg);
}

.dropdown:hover {
  color: grey;
}

.dropdown i {
  font-size: 36px;
  cursor: pointer;
}

.shape-info {
  padding-top: 0px;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.25s linear, opacity 0.25s linear,
    padding-top 0.25s linear;
}

.shape-info.show {
  margin-top: 10px;
  max-height: 90px;
  opacity: 1;
  overflow: unset;
}

.shape-info.show.full-width {
  max-height: 100%;
}

.shape-info.show::-webkit-scrollbar {
  width: 5px;
}

.shape-info.show::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.panel-textarea {
  display: flex;
  padding: 10px;
  color: $primary-dark;
  margin-bottom: 47px;
}

.panel-textarea textarea {
  resize: none;
  font-family: "montserrat";
  width: 59.1% !important;
  position: absolute;
  right: 0px;
  border: 1px solid $light-gold  !important;
  border-radius: 5px;
  margin: 0px 10px 100px 0px;
  padding: 10px;
  min-height: var(--input-min-height);
}

.panel-textarea textarea:focus {
  outline: none;
}

.panel-input {
  display: flex;
  padding: 10px;
  color: $primary-dark;
}

.panel-input label {
  position: relative;
  top: 10px;
  left: -5px;
}

.panel-input input {
  font-family: "montserrat";
  width: 59.1% !important;
  position: absolute;
  right: 0px;
  border: 1px solid $light-gold  !important;
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px;
  min-height: var(--input-min-height);
}

.panel-input input:focus {
  outline: none;
}

.panel-input select {
  padding: 10px;
  font-family: "montserrat";
  width: 59.1% !important;
  position: absolute;
  right: 0px;
  border: 1px solid $light-gold  !important;
  border-radius: 5px;
  margin-right: 10px;
  min-height: var(--input-min-height);
}

.panel-input select:focus {
  outline: none;
}

.panel-select {
  width: 59.1% !important;
  position: absolute !important;
  right: 0px !important;
  border: solid $light-gold;
  border-width: 1px !important;
  border-radius: 5px !important;
}

.advanced-parameters {
  border-top: 2px solid $primary-dark;
  border-bottom: 2px solid $primary-dark;
  font-family: "montserrat" !important;
  position: relative;
  min-width: 370px;
  right: 10px;
  color: $primary-dark  !important;
}

.active-advanced-parameters {
  border-top: none;
  border-bottom: 2px solid $primary-dark;
  font-family: "montserrat" !important;
  position: relative;
  min-width: 370px;
  right: 10px;
  color: $primary-dark  !important;
}

.dark-block {
  position: relative;
  width: 110%;
  max-width: 370px;
  right: 10px;
  border: solid $primary-dark;
  margin-bottom: 1em;
}

.ui.accordion .content.active.accordion-scroll {
  padding: 5px 0px 10px 0px;
}

.accordion-scroll::-webkit-scrollbar {
  width: 5px;
}

.accordion-scroll::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.accordion-scroll-large {
  position: relative;
  max-height: 30vh;
  padding: 0px 5px 0px 10px;
  margin: -8px -20px -8px -25px;
  overflow-y: auto;
  overflow-x: hidden;
}

.accordion-scroll-large::-webkit-scrollbar {
  width: 5px;
}

.accordion-scroll-large::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.ui.accordion .active.content .fields .field .inline.required.field {
  min-width: 330px;
  position: relative;
  left: -15px;
}

.ui.accordion .title {
  padding-left: 7px !important;
}

.ui.accordion .active.content .fields .field .inline.required.field .ui.right.labeled.input.input-bar {
  position: relative;
}

.field-title {
  font-weight: 500;
  font-size: small;
  color: $primary-dark;
  font-family: "montserrat";
  margin-bottom: 10px;
}

.ua-error {
  margin: 8px !important;
}

.met-editor.content-body {
  overflow: unset;
}

.content-body.unset-overflow {
  overflow: unset;
}