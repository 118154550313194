@import "../../colours";

.welcome-page {
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("../../backgroundCity-low-res.jpeg");
    background-size: cover;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1.0;
    transition: opacity 1.0s linear, z-index 0.8s linear ;
}

.welcome-page.unmount {
    opacity: 0.0;
    z-index: 0;
}

.app-page {
    overflow: hidden;
    filter: blur(8px) grayscale(100%);
    -webkit-filter: blur(8px) grayscale(100%);
    transition:  filter 1.0s linear, -webkit-filter 1.0s linear;
}

.app-page.mount{
    filter: blur(0px) grayscale(0%);
    -webkit-filter: blur(0px) grayscale(0%);
}

.bottom-bar .ui.button:hover, .bottom-bar .ui.button:focus {
    color: white !important;
    background-color: $dark-gold !important;
}

.bottom-bar .ui.button:focus:not(:hover) {
    color: white !important;
    background-color: transparent !important;
}

.modal.fullscreen {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000c2;
    z-index: 950;
}

.modal.fullscreen .panel-wrapper {
    width: 90%;
    height: 80%;
    margin: 0 auto;
    position: relative;
}

.modal.fullscreen .panel-wrapper-content {
    height: 100%;
}

.modal.fullscreen iframe {
    z-index: 100;
    width: 100%;
    height: 93%;
    padding: 10px 0 10px 0;
}

.user-agreement.panel-wrapper {
    top: auto !important;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: none;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.user-agreement-content {
    font-family: "montserrat";
    text-align: left;
    font-size: medium;
    color: $primary-dark;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5%;
}

.user-agreement-body {
    margin-bottom: 15px;
}

.user-agreement-panel {
    font-family: "montserrat";
    border-radius: 1%;
    background-color: white;
    width: 40%;
}

.user-agreement-title-panel {
    background: $dark-gold;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: grid;
    grid-template-columns: 80% 20%;
    font-family: "montserrat";
    font-size: large;
    align-items: center;
    margin-bottom: 5%;
    text-align: left;
    padding: 20px 5% 20px 5%;
} 

.user-agreement-title-panel img {
    width: 90%;
    max-width: 200px;
}
