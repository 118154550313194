@import "../../colours";

@font-face {
    font-family: "montserrat";
    src: url("../common/Montserrat-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.sidebar-div {
    box-shadow: 0 0 0;
    width: 50px;
    position: fixed;
    z-index: 100;
    top: 70px;
    left: 5px;
}

.sidebar-div .ui.vertical.menu {
    width: 100px;
    box-shadow: none;
    background: none;
    border: none;
}

.sidebar-div .ui.vertical.menu .item.icon-container:hover {
    background-color: white !important;
    color: $dark-gold;
    stroke: $dark-gold;
    fill: $dark-gold;
}

.sidebar-div .ui.vertical.menu .item.icon-container.disabled:hover {
    background-color: darkgrey !important;
    color: rgba(40, 40, 40, 0.3) !important;
    stroke: white !important;
    fill: white !important;
}

.sidebar-div .ui.vertical.menu .active.item.icon-container:hover {
    background-color: $dark-gold !important;
    stroke: $primary-light;
    fill: $primary-light;
}

i {
    color: inherit !important;
}

.sidebar-div a.item i {
    font-size: x-large;
}

.ui.vertical.menu .item.icon-container.disabled {
    background: darkgrey !important;
}


.ui.vertical.menu .item.icon-container {
    background: $dark-gold !important;
    padding-top: 4px !important;
    margin: 4px!important;
    border-width: 3px!important;
    border-radius: 0.75rem !important;
    stroke: $primary-light;
    fill: $primary-light;
}

.ui.vertical.menu .active.item.icon-container {
    background: $primary-dark !important;
}

.ui.modal {
    color: black;
}

.menu-option {
    position: fixed;
    left: 68px;
    top: 55px;
    width: 370px;
    z-index: 100;
}

.run-config .content-body {
    overflow: unset;
}

.sidebar-icon {
    position: relative !important;
    left: 0px !important;
    top: 5px !important;
    width:32px;
    height:32px; 
}

.ui.menu .ui.selection.dropdown .menu > .item {
    background: white !important;
    color: black !important;
}

.ui.menu .ui.selection.dropdown .ui.menu > .active.selected.item {
    background: lightgrey !important;
    color: black !important;
}

.simulation-wrapper {
    border-radius: 10px;
    background-color: white;
    position: absolute;
}

.simulation-types Button, .ui.button.download-report {
    width: 100%;
    border-radius: 5px !important;
    background-color: $dark-gold !important;
    margin: 0px 0px 2px 0px !important;
}
.simulation-types Button:hover, .ui.button.download-report:hover {
    background-color: $primary-dark !important;
    color: white !important;
}

.ui.button.download-report {
    width: 95%;
    align-self: center;
}

.exit-icon {
    position: relative;
    left: 17px;
    bottom: 3px;
    cursor: pointer;
}

.run-simulation-menu Button {
    width: 100%;
    border-radius: 5px !important;
    margin-top: 251px !important;
}

.releaseTypes {
    margin: 2px 2px 10px 2px;
    display: flex;
}

.releaseTypes button {
    font-family: "montserrat";
    background-color: $primary-dark;
    border: none;
    width: 50%;
    padding: 10px 5px 10px 5px !important;
    margin: 1px !important; 
    border-radius: 5px !important;
}

.releaseTypes button:active {
    outline: none;
}

.agentTypes {
    margin: 15px 0px 15px;
    max-width: 370px !important;
    display: flex;
    justify-content: center;
}

.agentTypes button {
    font-family: "montserrat";
    background-color: $primary-dark;
    border: none;
    width: 33%;
    padding: 15px 5px 15px 5px !important;
    margin: 1px !important; 
    border-radius: 5px !important;
}

.agentTypes button:active {
    outline: none;
}

.agentTypes button[disabled] {
    background-color: grey;
}

.source-title {
    color: $primary-dark;
    font-family: "montserrat";
    margin-left: 5px;
    margin-top: 5px;
}

.simulation-status-title {
    opacity: 0.8;
    color: $primary-dark;
    font-family: "montserrat";
    font-weight: bold;
    margin: 5px;
}

.met-dropdown {
    padding: 10px !important;
}

.met-dropdown .ui.selection.dropdown {
    margin-left: 5px  !important;
}

.bottom-bar {
    text-align: center;
    bottom: 0px;
    width: 100%;
    max-width: 370px;
    height: 36px;
    background-color: $primary-dark;
    display: flex;
    align-items: center;
}

.bottom-bar .finished{
    flex: 1;
}

.bottom-bar > .ui.button {
    background-color: $primary-dark;
}

.keyBuilds-display {
    color: black;
}

.filter-display {
    padding-bottom: 10px;
}

.table-display-div {
    max-height: 120px;
    overflow-y: auto;
}

.table-display-div::-webkit-scrollbar {
    width: 5px;
}

.table-display-div::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.table-display {
    width: 97%;
    margin: 5px;
}

.table-display td {
    margin: 5px;
}

.table-display .active {
    background-color: $dark-gold;
    color: $primary-light;
    font-family: "montserrat";
    cursor: pointer;
}

.run-simulation {
    display: grid;
}

.run-simulation Button {
    margin: 5px 0px 5px 0px !important;
    border-radius: 5px !important;
}

.create-source {
    margin: 15px 0px 15px;
    max-width: 350px !important;
    display: flex;
    justify-content: center;
}

.create-source button {
    font-family: "montserrat";
    align-items: center;
    text-align: center;
    background-color: $primary-dark;
    border: none;
    padding: 10px  !important;
    border-radius: 5px !important;
}

.create-source button:active {
    outline: none;
}

.progress-timer-cell .ui.progress .bar {
    background: #b1afaf;
}

.ui.progress.simulation-progress {
    margin: 0 !important;
    text-align: left;
    grid-area: 1/1;
}

.scenario-table {
    width: 100%;
}

.scenario-row {
    color: $primary-dark;
    background-color: $pale-gold;
    font-family: "montserrat";
    border-color:$dark-gold!important;
    border-bottom: 1px solid;
    width: 100%;
    cursor: pointer;
}

.scenario-row:hover {
    color: white;
    background-color: $light-gold;
}

.scenario-row  .archive-icon {
    display: none;
}

.scenario-row:hover .archive-icon {
    display: block;
    color: $primary-dark;
}

.simulation-row {
    color: $primary-dark;
    background-color: $pale-gold;
    font-family: "montserrat";
    border-color:$dark-gold!important;
    border-bottom: 1px solid;
    cursor: pointer;
}

.simulation-row:hover {
    color: white;
    background-color: $light-gold;
}

.simulation-name {
    font-family: "montserrat";
    margin-left: 5px;
    padding: 5px;
}

td.simulation-progress {
    width: 150px;
}

.ua-progress {
    display: grid;
}

.simulation-timer {
    text-align: center;
    width: 150px;
    z-index: 15;
    grid-area: 1/1;
}

.cancel-sim {
    width: 42px;
    padding: 1px;
    padding-left: 10px;
}

.simulation-model {
    text-align: center;
    width: 70px;
}

.incident-row {
    color: $primary-dark;
    background-color: $pale-gold;
    font-family: "montserrat";
    border-color:$dark-gold!important;
    border-bottom: 1px solid;
}

.incident-row:hover {
    color: white;
    background-color: $light-gold;
}

.incident-cell {
    font-family: "montserrat";
    cursor: pointer;
}

.met-row {
    color: $primary-dark;
    background-color: $pale-gold;
    font-family: "montserrat";
    border-color:$dark-gold!important;
    border-bottom: 1px solid;
}

.met-row:hover {
    color: white;
    background-color: $light-gold;
}

.met-cell {
    font-family: "montserrat";
    cursor: pointer;
}

.fixed {
    position: fixed;
}

.create-contour-output-buttons div.ui.buttons {
    position: relative;
    left: 100%;
    transform: translate(-260%,);
}

.create-contour-output-buttons div.ui.buttons button.ui.icon.button {
    margin: 1px;
    padding: 10px;
    background-color: $primary-dark;
}

.add-contour-output-buttons div.ui.buttons {
    position: relative;
    left: 100%;
    transform: translate(-100%,);
}

.add-contour-output-buttons div.ui.buttons button.ui.icon.button {
    margin: 1px;
    padding: 10px;
    background-color: $primary-dark;
}

.contour-table {
    min-width: 330px;
    max-height: 250px;
    overflow-x: auto;
  }
  
.contour-table .ui.table tr.active {
    background: rgba(255, 165, 0, 0.5) !important;
    color: $primary-light;
}
.contour-table thead {
    border: none;
    border-bottom: grey;
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.contour-table th,
.contour-table td {
    border: none !important;
    background: none !important;
    color: $primary-dark !important;
}

.contour-table table {
    background: none !important;
    border: none !important;
}

.output-note {
    font-family: "montserrat";
    color: $primary-dark;
}

.ua-tab {
    background: white !important;
}

.ua-tab .ui.attached.tabular.menu{
    background: #666666 !important;
}

.ua-tab .ui.segment {
    background: white !important;
    border: none !important;
    padding: 0;
}

.ua-tab .item {
    color: white !important;
    border: 1px solid transparent !important;
    border-top: 2px solid transparent !important;
    border-bottom: none !important;
    padding: .92857143em 1.42857143em !important;
    font-size: 1rem !important;
}

.ua-tab .active.item {
    background: white !important;
    color: grey !important;
}

.incident-template-content-body {
    padding: 0px 10px 10px 10px;
}

.content-body {
    padding: 0px 10px 10px 10px;
    max-height: 69vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.content-body::-webkit-scrollbar {
    width: 5px;
}

.content-body::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.cancel-simulation {
    background-color: $dark-gold !important;
}

.cancel-simulation:hover {
    color: $primary-dark !important;
}

.cancel-simulation:active {
    background-color: $pale-gold !important;
}

.cancel-simulation:focus {
    background-color: $pale-gold !important;
}

.accept-button {
    background-color: $dark-gold !important;
}

::-webkit-calendar-picker-indicator {
    cursor: pointer;
}