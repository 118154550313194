@import "../../../../colours.scss";

.search-panel {
    display: grid
}

.search-panel .input-boxes {
    display: flex;
    flex-direction: row;
    grid-row: 1 / span 1;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: inherit
}

.input-boxes .coordinate-input{
    flex: 3;
    width: 150px
}

.input-boxes .coordinate-input .input {
    width: 100%;
    padding: 0px
}
