.aois-display {
    min-width: 300px;
}

.building.data-breakdown {
    max-height: 300px;
    padding: 0px;
}

.breakdown-info {
    overflow: auto;
    padding-top: 0px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition:  max-height 0.25s linear, opacity 0.25s linear, padding-top 0.25s linear;
}

.breakdown-info.show {
    padding-top: 5px;
    max-height: 250px;
    opacity: 1.0;
    overflow: auto;
}

.sub-entry {
    background: lightgrey;
    color: grey;
    font-size: medium;
    padding: 5px;
}

.keyBuild-editor .content-body {
    overflow: unset;
}

.keyBuild-editor .ui.active.text.centered.inline.loader {
    color: grey;
    font-size: small;
    font-family: "montserrat";
    font-weight: bold;
    margin: 30px;
}

.build-type-selection {
    overflow: unset;
}