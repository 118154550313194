@import '../../colours';

@font-face {
    font-family: "montserrat";
    src: url("../common/Montserrat-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    --input-min-height: 40px;
    --input-width: 250px;
}

.invisible {
    display: none !important;
}

.popup {
    color: $primary-dark !important;
    background-color: white !important;
    border: 1px solid $primary-dark !important;
    z-index: 950 !important;
    position: absolute !important;
}

.panel-wrapper {
    position: relative;
    // border-top: solid;
    // border-color: black;
    // border-radius: 0.75rem;
    // border-width: 3px;
}

.panel-wrapper-head {
    overflow: hidden;
    background: black;
}

.panel-wrapper-header {
    background: $primary-dark;
    padding: 8px 13px 8px 13px;
    min-width: 200px;
    display: flow-root;
}

.panel-wrapper-header .title {
    font-family: "montserrat";
    float: left;
    font-size: 16px;
}

.panel-wrapper-header .icon {
    float: right;
    cursor: pointer;
}

.panel-wrapper-header .icon:hover {
    color: yellow !important;
}

.panel-wrapper-content {
    opacity: 0.0;
    transition: opacity 1.0s linear;
}

.panel-wrapper-content.mounted {
    opacity: 1.0;
}

.panel-wrapper {
    position: fixed;
    top: 76px !important;
    width: 0px;
    max-height: 0;
    background: $primary-light !important;
    z-index: 900;
    transition:  width 0.25s linear, max-height 0.25s linear;
    height: auto;
    min-height: 140px;
    overflow-y: auto;
    overflow-x: hidden;
}

.panel-wrapper::-webkit-scrollbar {
    width: 5px;
    // padding: 15px 0px 15px 0px;
}

.panel-wrapper::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
  border-radius: 0%;
}

.panel-wrapper.mounted {
    top: 50px;
    width: 370px;
    background: $primary-light !important;
    z-index: 900;
    max-height: 83vh;
    overflow: unset;
}

.right-sidebar .panel-wrapper {
    right: 60px;
    overflow: unset;
}

.input-bar .ui.dropdown.label, .input-bar .ui.dropdown.label > .text {
    background-color: $dark-gold !important;    
    color: white !important;
}

.ui.menu .ui.dropdown.label.units-dropdown .menu > .item:hover {
    background: $dark-gold !important;
}

.ui.menu .ui.dropdown.label.units-dropdown .menu > .item {
    color: black !important;
    background:  white !important;
    background-color: white !important;
}

.ui.menu .ui.dropdown.label.units-dropdown .menu .active.selected.item {
    background-color: white !important; 
}

.ui.menu .ui.dropdown.label.units-dropdown .menu .active.selected.item:hover{
    background: $dark-gold !important;
}

input:-moz-read-only { /* For Firefox */
    background-color: rgba(255, 255, 255, 0.27) !important;
}

input:read-only {
    background-color: rgba(255, 255, 255, 0.27) !important;
}

.units-dropdown {
    width: 87px;
    text-align: center !important;
}

.input-field {
    margin: 18px 0px 18px 0px;
    display: grid;
    grid-template-columns: 2;
}

.input-field label {
    width: 130px;
}

.input-field Input {
    color: $primary-dark !important;
    border: solid $light-gold !important;
    border-width: 1px !important;
}

.shape-info .fields > .field .ui.input.input-bar {
    width: 85% !important;
}

.ui.form.ua-form .fields .inline.field .shape-info .fields > .field .ui.input.input-bar {
    width: 99% !important;
}

.shape-info .fields > .field .ui.input {
    width: 99% !important;
}

.shape-info .fields .field > label {
    width: 99% !important;
}


.links {
    justify-self: right;
    font-size: small;
}

.language {
    font-size: small;
}

.copyright {
    font-size: small;
    white-space: nowrap;
    padding: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.copyright span {
    font-family: "montserrat" !important;
}

.footer-logo {
    height: var(--app-footer-height);
    padding: 7px;
    margin-right: 24px;
}

.touchable {
    cursor: pointer;
}

.language-selector {
    width: 80px;
    color: black;
}

.button-svg-icon {
    padding: 6px 11px 6px 11px !important;
    height: 36px !important;
    width: 22px !important;
    margin: 0px 0px 0px 0px !important;
}

.footer {
    background-color: var(--app-footer-background);
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 300;
    height: var(--app-footer-height);
    display: flex;
}
.footer-left {
    display: flex;
    // The footer is display: flex, so 100% width here is the maximum
    // and is balanced with the other children
    width: 100%;
    height: 100%;
    justify-content: left;
    align-items: center;
}
.footer-middle {
    display: flex;
    // The footer is display: flex, so 100% width here is the maximum
    // and is balanced with the other children
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.footer-right {
    display: flex;
    // The footer is display: flex, so 100% width here is the maximum
    // and is balanced with the other children
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: center;
}
.footer select option {
    font-family: "montserrat";
}
