$primary-dark: grey;
$primary-light: lightgrey;

@font-face {
  font-family: "montserrat";
  src: url("components/common/Montserrat-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font: 12px/26px Arial, Helvetica, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: "montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

main {
  display: flex;
}

.centered {
  text-align: center;
}

.ui.form .success.message,
.ui.form .warning.message,
.ui.form .error.message {
  display: block;
}

.ui.inverted.dimmer .ui.loader {
  color: black;
}

.ui.sortable.table thead th {
  /* border-left: 1px solid grey; */
  color: black;
  border: none;
}

.ui.button {
  color: white
}

.ui.table,
.ui.table thead th {
  color: white
}

/* Semantic Ui Calendar React style */

/* Selector for the pointer on the calendar popup */
.ui.popup:has(.suicr-year-view-header):before,
.ui.popup:has(.suicr-month-view-header):before,
.ui.popup:has(.suicr-day-view-header):before,
.ui.popup:has(.suicr-hour-view-header):before,
.ui.popup:has(.suicr-minute-view-header):before {
  background: #444444 !important;
}
.suicr-year-view-header *,
.suicr-month-view-header *,
.suicr-day-view-header *,
.suicr-hour-view-header *,
.suicr-minute-view-header * {
  background-color: #444444 !important;
}
.suicr-content-item {
  color: $primary-dark;
}
.disabled .suicr-content-item {
  color: $primary-light;
}