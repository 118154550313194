@import "../../colours.scss";

@font-face {
    font-family: "montserrat";
    src: url("../common/Montserrat-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.map-div {
    z-index: 1;
}

path {
    stroke-opacity: 1;
}

// Override the leaflet tile pane to uze automatic z-index, allowing separate tiles layers
// to use their own z-index properties
.leaflet-tile-pane {
    z-index: auto !important;
}

.leaflet-bar-timecontrol.leaflet-control {
    font-family: "Montserrat";
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 885px;
    border: solid $primary-dark;
    border-width: 1px;
    border-radius: 5px;
    opacity: 1;
}

.leaflet-bar-timecontrol .leaflet-control-timecontrol {
    background: white;
    border-width: 0 0 0 0;
    color: $primary-dark;
}

.leaflet-control-timecontrol.timecontrol-slider.timecontrol-dateslider .slider {
    background: $dark-gold;
    width: 475px;
    opacity: 0.8;
}

.leaflet-control-timecontrol.timecontrol-slider.timecontrol-speed .slider {
    background: $dark-gold;
    opacity: 0.8;
}

.timecontrol-slider .knob {
    position: absolute;
    width: 9px;
    height: 14px;
    background-color: $primary-dark;
    border-radius: 50px;
    margin-top: -2px;
    margin-left: -4px;
    cursor: ew-resize;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    opacity: 1.0;
}

.leaflet-bar-timecontrol .leaflet-control-timecontrol.timecontrol-loop {
    font-weight: bold;
    color: #40404070;
}

.leaflet-bar-timecontrol .leaflet-control-timecontrol.timecontrol-loop.looped {
    color: $primary-dark;
}

.leaflet-popup-content-wrapper {
    background: white;
    color: $primary-dark;
    border: solid;
    border-color: $primary-dark;
    border-width: 2px;
}

.leaflet-popup-content {
    margin: 0;
}

.leaflet-container a.leaflet-popup-close-button {
    color: $primary-dark;
}

.leaflet-popup-tip {
    background: white;
    border: solid;
    border-color: $primary-dark;
    border-width: 2px;
    border-top: none;
    border-left: none;
    box-shadow: none;
}

.leaflet-popup-tip-container {
    overflow: visible;
}

.ua-popup {
    position: absolute;
    top: 22%;
    left: 50%;
    z-index: 401;
    padding: 10px;
    background: #000000a6;
    color: white;
}

.ua-popup.key-build {
    max-height: 600px;
    max-width: 600px;
    min-width: 300px;
}

.ua-popup.sensors {
    max-height: 600px;
    max-width: 800px;
    min-width: 700px;
}

.ua-popup .chart-div {
    margin: 0px 10px;
    border: #ffffff3b solid 2px;
    border-radius: 5px;
    background: #00000075;
    padding: 10px;
}

.ua-popup .chart-div .yaxis {
    font-size: 14px;
}

.ua-popup .chart-div .x.axis {
    font-size: 14px;
}

.legend-title span {
    color: white;
}

.plot-with-legend .label text {
    fill: white !important;
}

.chart-selection {
    display: table;
    width: 100%;
}

.chart-data-type-selection {
    color: white;
    display: table-row;
    width: 100%;
}

.chart-data-type-selection>div {
    display: table-cell !important;
}

.chart-data-type-selection .ui.radio.checkbox {
    font-size: 12px;
    margin: 5px 15px 10px 0px !important;
}

.chart-data-type-selection .ui.radio.checkbox>input[type="radio"]:checked+*::before {
    background: $dark-gold;
    border-color: white;
}

.chart-data-type-selection .ui.radio.checkbox>input[type="radio"]:checked+* {
    color: white;
}

.chart-data-type-selection .ui.radio.checkbox label {
    color: white !important;
}

.legend-title {
    padding: 5px 10px;
}

.legend-title .rv-discrete-color-legend-item {
    display: inline-block;
    padding: 0 10px 0 0;
}

.legend-title .rv-discrete-color-legend-item__title {
    margin-left: 5px !important;
}

.data-legend {
    font-family: "Montserrat";
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.data-legend h4 {
    margin: 0px 0px -10px;
    color: $primary-dark;
    font-weight: bold;
}

.legend {
    text-align: left;
    line-height: 100%;
    white-space: nowrap;
    color: $primary-dark;
}

.legend i {
    min-width: 10px;
    max-width: 20px;
    min-height: 10px;
    max-height: 20px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.leaflet-container {
    font-family: "Montserrat";
    font-weight: 100;
    color: $primary-dark;
}

.ui.dropdown.ua-dropdown.dark {
    color: white !important;
    border: 1px solid white;
    border-radius: 5px;
    padding: 0 5px 0 5px;
    flex: 1;
    margin-left: 5px;
    text-align: end;
}

.output-popup {
    min-width: 370px;
    max-width: 470px;
}

.output-popup .ui.basic.table td {
    padding: 5px !important;
}