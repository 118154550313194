// CSS responsible for setting root level layout variables

// See app.js for the assignation of root's className 
:root.riskaware.show-header {
    --app-footer-height: 30px;
    --scenario-window-height: 80%;
}
:root.riskaware:not(.show-header) {
    --app-footer-height: 60px;
    --scenario-window-height: 80%;
}
:root.smiths {
    --app-footer-height: 60px;
    --scenario-window-height: 70%;
}